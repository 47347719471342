import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
import useSiteMetadata from './SiteMetadata'
import {graphql, StaticQuery, withPrefix} from "gatsby"

const TemplateWrapper = ({ children, noFooter, footerData }) => {
  const { title, description } = useSiteMetadata()
  // console.log(footerData)
    let footer;
    if (!noFooter) {
        footer = <Footer afspraak={footerData.afspraak}
                         facebook={footerData.facebook}
                         instagram={footerData.instagram}
                         adres={footerData.adres}
                         contactgegevens={footerData.contactgegevens}
                         openingsuren={footerData.openingsuren}/>
    }
  return (
    <div>
      <Helmet>
        <html lang="en"/>
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix("/")}img/og-image.jpg`} />
      </Helmet>
      <Navbar afspraak={footerData.afspraak}
              facebook={footerData.facebook}
              instagram={footerData.instagram}/>
        {children}
        {footer}
    </div>
  )
}

const Layout = ({ children, noFooter }) => {
  return <StaticQuery
    query={graphql`
        query {
      markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
        frontmatter {
          adres
          contactgegevens
          openingsuren
          facebook
          instagram
          afspraak
        }
      }
        }
      `}
    render={data => <TemplateWrapper footerData={data.markdownRemark.frontmatter} children={children} noFooter={noFooter}/>}
  />
};


export default Layout
