import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import {getUrlFromImage} from "../util/CommonUtil";
import empty from '../../static/img/background-1.jpg';

export const GroepslessenPageTemplate = ({titel, groepslessen}) => {
    return (
        <div>
            <section className="basic-header">
                <h1>{titel}</h1>
            </section>
            <section className="section-groepslessen">
                <div className="container" style={{marginBottom: '50px'}}>
                    {groepslessen.map((groepsles, index) => (
                      <div className="groepsles-item" key={index}>
                          <div className="les-image" style={{background: `url(${groepsles && groepsles.image ? getUrlFromImage(groepsles.image) : empty}) center center / cover no-repeat`}}></div>
                          <div className="les-info">
                              <h4>{groepsles.naam}</h4>
                              <h5>
                                {groepsles.datum ? <span className="info">{groepsles.datum}</span> : ''}
                                {groepsles.prijs ? <span className="prijs">{groepsles.prijs}</span> : ''}
                              </h5>
                              <p>{groepsles.info}</p>
                          </div>
                      </div>
                    ))}

                </div>

            </section>
        </div>
    )
}

GroepslessenPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const GroepslessenPage = ({data}) => {
    const {markdownRemark: post} = data;
    // console.log(post)
    return (
        <Layout>
            <GroepslessenPageTemplate
              titel={post.frontmatter.title}
              groepslessen={post.frontmatter.lessen}
            />
        </Layout>
    )
}

GroepslessenPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default GroepslessenPage

export const groepslessenPageQuery = graphql`
  query GroepslessenPage {
    markdownRemark(frontmatter: { templateKey: { eq: "groepslessen-page" } }) {
      frontmatter {
        title
        lessen {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            naam
            datum
            prijs
            info
        }

      }
    }
  }
`
