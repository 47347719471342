import React from "react";
import {HTMLContent} from "./Content";

const Openingsuren = (props) => (
    <div className="contact-item openingsuren">
        <h4 className="contact-item-title">Openingsuren</h4>
        <div className="contact-item-content">
          <HTMLContent content={props.openingsuren} />
          {/*<b>ma - vr  </b>8u tot 20u*/}
        </div>
    </div>
);

export default Openingsuren;