import React from 'react'
import { Link } from 'gatsby'
// import logo from '../../static/img/logo-full.svg'
import logo from '../../static/img/logo.png'
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
// import Socials from './Socials'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav className="navbar is-transparent is-fixed-top" role="navigation" aria-label="main-navigation">
        <div className="container">
          <div className="navbar-brand" style={{position: 'absolute', width: '100%'}}>
            <Link to="/" className="navbar-item logo" title="Logo">
              <img src={logo} alt="Beweegpunt logo"/>
            </Link>
            {/* Hamburger menu */}
            <div className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}>
              <span />
              <span />
              <span />
            </div>
          </div>
          <div id="navMenu" className={`navbar-menu ${this.state.navBarActiveClass}`}>
            <div className="navbar-start has-text-centered navbar-items" style={{width: '100%', justifyContent: 'center'}}>
              <a className="navbar-item socials-item" target="_blank" rel="noopener noreferrer" href={this.props.afspraak}>Maak hier je afspraak</a>
              <Link className="navbar-item" to="/">Home</Link>
              <Link className="navbar-item" to="/team">Team</Link>
              <Link className="navbar-item" to="/aanbod">Aanbod</Link>
              <Link className="navbar-item" to="/groepslessen">Groepslessen</Link>
              <Link className="navbar-item" to="/contact">Contact</Link>
              <a className="navbar-item socials-item" href={this.props.facebook} target="_blank" rel="noopener noreferrer">Facebook</a>
              <a className="navbar-item socials-item" href={this.props.instagram} target="_blank" rel="noopener noreferrer">Instagram</a>
            </div>

          </div>
          <div className="navbar-end">
            <div className="socials">
              <a href={this.props.facebook} target="_blank" rel="noopener noreferrer"><img className="facebook" src={facebook} alt="Facebook"/></a>
              <a href={this.props.instagram} target="_blank" rel="noopener noreferrer"><img className="instagram" src={instagram} alt="Instagram"/></a>
            </div>
            <a href={this.props.afspraak} target="_blank" rel="noopener noreferrer" className="btn-navbar" style={{width: '210px'}}>Maak hier je afspraak</a>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
