import React from "react";
import {HTMLContent} from "./Content";

const Contactgegevens = (props) => (
    <div className="contact-item contactgegevens">
        <h4 className="contact-item-title">Contactgegevens</h4>
        <div className="contact-item-content">
          <HTMLContent content={props.contactgegevens} />
            {/*<a className="link" href="tel:+03 488 50 11">*/}
            {/*    T 03 488 50 11 (bij voorkeur tussen 9 - 12u)*/}
            {/*</a><br/>*/}
            {/*<a className="link" href="mailto:info@beweegpunt.be">*/}
            {/*    info@beweegpunt.be*/}
            {/*</a><br/>*/}
            {/*<span className="link" href="#">*/}
            {/*    Ondernemingsnr 0810593168*/}
            {/*</span>*/}
        </div>
    </div>
);

export default Contactgegevens;