import React from 'react'

// import logo from '../../static/img/logo-full.svg'
import logo from '../../static/img/logo.png'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'

import Contactgegevens from "./Contactgegevens";
import Adres from "./Adres";
import Openingsuren from "./Openingsuren";

const Footer = class extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <footer className="bwp-footer">
        <div className="container">
          <div className="columns">
            <div className="column footer-logo">
              <img src={logo} alt="Beweegpunt" height="62"/>
            </div>
            <div className="column">
              <p className="has-text-centered">
                Alle kinesitherapeuten bij Beweegpunt <br/>
                zijn niet-geconventioneerd.
              </p>
            </div>
            <div className="column">
              <div className="footer-right">
                <div className="socials">
                  <a href={this.props.facebook} target="_blank" rel="noopener noreferrer"><img className="facebook" src={facebook} alt="Facebook" height="32"/></a>
                  <a href={this.props.instagram} href="https://www.instagram.com/beweegpunt.lier/" target="_blank" rel="noopener noreferrer"><img className="instagram" src={instagram} alt="Instagram" height="32"/></a>
                </div>
                <a href={this.props.afspraak} target="_blank" rel="noopener noreferrer" className="btn-home" style={{width: '210px'}}>Maak hier je afspraak</a>
              </div>
            </div>

          </div>
          <div className="columns">
            <div className="column">
              <Contactgegevens contactgegevens={this.props.contactgegevens}/>
            </div>
            <div className="column has-text-centered">
              <Adres adres={this.props.adres}/>
            </div>
            <div className="column has-text-right">
              <Openingsuren openingsuren={this.props.openingsuren}/>
            </div>
          </div>
        </div>
        <p className="footer-end"><a href="https://www.digitalewachtkamer.be/privacymelding.aspx" target="_blank" rel="noopener noreferrer">Privacymelding</a></p>
      </footer>
    )
  }
}

export default Footer
