import React from "react";
import {HTMLContent} from "./Content";

const Adres = (props) => (
    <div className="contact-item adres">
        <h4 className="contact-item-title">Adres</h4>
        <div className="contact-item-content">
          <HTMLContent content={props.adres} />

            {/*Berlarij 29<br />2500 Lier*/}
        </div>
    </div>
);

export default Adres;